import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "import-icons"
    }}>{`Import Icons`}</h1>
    <p>{`Our iconography comes from the streamline icon set. The design team has designated a subset of those icons as common. We have added to that list for Build. You can see them `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/Hw3JQszjbsSpPDuimL1v96/Build-Icon-Library?node-id=0%3A1"
      }}>{`here`}</a>{`. This script will help import icons into builder-ui should more be added to the common set.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <ol>
      <li parentName="ol">{`Export the new svg icons from figma and paste them into `}<inlineCode parentName="li">{`./app/src/icons/raw/`}</inlineCode></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`node scripts/import-icons`}</inlineCode></li>
      <li parentName="ol">{`Address any errors that come up in the terminal`}</li>
      <li parentName="ol">{`Your icons should now appear in the docs and be accessible through `}<inlineCode parentName="li">{`<Icons.* />`}</inlineCode></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      